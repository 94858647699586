
import { defineComponent, ref } from 'vue';
import { useSendResetPasswordMailMutation } from '@/generated/graphql';
import { useRouter } from 'vue-router';
import * as yup from 'yup';

import { Input, Button, Emoji } from '@bridge-immo/ui';
import { SchemaFormWithValidation } from '@/components/SchemaFormWithValidation';
export default defineComponent({
  components: {
    Emoji,
    SchemaFormWithValidation,
    Button
  },
  setup() {
    const forgotPasswordFormSchema = {
      email: {
        component: Input,
        label: 'Adresse Email',
        placeholder: 'jordan.belfort@bridge.immo',
        type: 'email',
        name: 'email',
        validations: yup
          .string()
          .required()
          .email(),
        autocomplete: 'email',
        icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
               </svg>`
      }
    };
    const forgotPasswordFormData = ref({ email: '' });
    const forgotPasswordForm = ref(null);

    const {
      mutate: sendResetPasswordMailMutation,
      onDone,
      onError,
      loading
    } = useSendResetPasswordMailMutation({});
    const sendResetPasswordMail = () => {
      sendResetPasswordMailMutation(forgotPasswordFormData.value);
      forgotPasswordFormData.value.email = '';
      (forgotPasswordForm.value as any).resetForm();
    };

    onDone(async result => {
      if (result.data?.sendResetPasswordMail) {
        // TODO VL : display message
      }
    });

    onError(async result => {
      console.log(result);
    });

    const router = useRouter();
    const goToLogin = () => {
      router.push({ name: 'login' });
    };

    return {
      forgotPasswordFormData,
      forgotPasswordFormSchema,
      sendResetPasswordMail,
      loading,
      goToLogin,
      forgotPasswordForm
    };
  }
});
