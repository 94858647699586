<template xmlns:SchemaFormWithValidation="http://www.w3.org/1999/html">
  <div class="min-h-screen bg-white flex">
    <div class="flex flex-1 pb-16 sm:pt-8 sm:pb-24 flex-col lg:flex-none ">
      <div class="px-4 sm:px-12 h-16 flex justify-between items-center ">
        <Button type="text" @click="goToLogin" color="black">
          <template #icon-before>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </template>
          Retour
        </Button>
        <p class="text-sm text-gray-400">
          Vous avez déjà un compte ?
          <Button type="link" @click="goToLogin">Se connecter</Button>
        </p>
      </div>
      <div class="flex-1 flex flex-col justify-center px-4 py-8 sm:px-6 lg:px-20 xl:px-24">
        <div class="mx-auto w-full max-w-sm lg:w-96">
          <div>
            <h2 class="mt-6 text-3xl font-extrabold text-gray-900">
              Mot de passe <span class="text-primary-500">oublié</span>
              <Emoji class="pl-1" data="😱"></Emoji>
            </h2>
            <p class="pt-4 text-gray-400 tracking-wide font-light">
              Vous avez oublié votre mot de passe ? Pas de panique, indiquez votre adresse mail et
              nous vous enverrons un lien permettant de réinitialiser votre mot de passe.
            </p>
          </div>

          <div class="mt-8">
            <div class="mt-6">
              <SchemaFormWithValidation
                ref="forgotPasswordForm"
                class="space-y-6"
                :schema="forgotPasswordFormSchema"
                v-model="forgotPasswordFormData"
                @submit="sendResetPasswordMail"
              >
                <template #afterForm>
                  <Button type="submit" color="success" :loading="loading">Réinitialiser</Button>
                  <p class="text-xs text-gray-300 font-light text-center mt-4">
                    <svg
                      class="h-4 w-4 inline"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
                      />
                    </svg>
                    Vos informations sont sécurisées
                  </p>
                </template>
              </SchemaFormWithValidation>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="hidden lg:flex relative w-0 flex-1 bg-primary-500 justify-center items-center">
      <div class="text-white max-w-md p-16 xl:max-w-xl">
        <h3 class=" text-4xl xl:text-5xl font-semibold">
          Nous vous souhaitons un bon retour sur Bridge <Emoji data="👋"></Emoji>
        </h3>
        <p class="text-xl xl:text-2xl mt-10 font-light ">
          Après votre connexion, vous pourrez de nouveau gérer vos dossiers de locataire, planifier
          vos visites, télécharger votre bail, accéder à des conseils d’experts et bien d’autres
          fonctionnalités encore !
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { useSendResetPasswordMailMutation } from '@/generated/graphql';
import { useRouter } from 'vue-router';
import * as yup from 'yup';

import { Input, Button, Emoji } from '@bridge-immo/ui';
import { SchemaFormWithValidation } from '@/components/SchemaFormWithValidation';
export default defineComponent({
  components: {
    Emoji,
    SchemaFormWithValidation,
    Button
  },
  setup() {
    const forgotPasswordFormSchema = {
      email: {
        component: Input,
        label: 'Adresse Email',
        placeholder: 'jordan.belfort@bridge.immo',
        type: 'email',
        name: 'email',
        validations: yup
          .string()
          .required()
          .email(),
        autocomplete: 'email',
        icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
               </svg>`
      }
    };
    const forgotPasswordFormData = ref({ email: '' });
    const forgotPasswordForm = ref(null);

    const {
      mutate: sendResetPasswordMailMutation,
      onDone,
      onError,
      loading
    } = useSendResetPasswordMailMutation({});
    const sendResetPasswordMail = () => {
      sendResetPasswordMailMutation(forgotPasswordFormData.value);
      forgotPasswordFormData.value.email = '';
      (forgotPasswordForm.value as any).resetForm();
    };

    onDone(async result => {
      if (result.data?.sendResetPasswordMail) {
        // TODO VL : display message
      }
    });

    onError(async result => {
      console.log(result);
    });

    const router = useRouter();
    const goToLogin = () => {
      router.push({ name: 'login' });
    };

    return {
      forgotPasswordFormData,
      forgotPasswordFormSchema,
      sendResetPasswordMail,
      loading,
      goToLogin,
      forgotPasswordForm
    };
  }
});
</script>
